import { useApolloClient, useMutation } from '@apollo/client/react/hooks';
import {
  InitiateRefundEvent,
  IntegrationPartner,
  Order,
  OrderAction,
  OrderEvent,
  OrderStatus,
} from '@oolio-group/domain';
import { computeOrderState } from '@oolio-group/order-helper';
import { Dictionary } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { GET_ORDERS, ORDER_SAVE } from '../../../hooks/app/orders/graphql';
import {
  alertAndPrintTriggerVar,
  pendingOnlineOrdersCountVar,
} from '../../../state/cache';
import { orderUpdateEventsValidation } from '../../../utils/eventValidator';
import { isValidOnlineOrder } from '../../../utils/OnlineOrdersHelper';
import kitchenOrderEvents from '../../../utils/printerTemplates/kotEvents';
import { useIntegrationPartners } from '../useIntegrationPartners/useIntegrationPartners';
import { useSession } from '../useSession';
import { useOnlineOrderEvents } from './useOnlineOrderEvents';
import { useReservations } from '../reservations/useReservations';

export interface useOrderEvents {
  orderEventsHandler: (events: OrderEvent[]) => Promise<void>;
  handleOrderSave?: (saveOrder: Order) => void;
}

export const useOrderEvents = (): useOrderEvents => {
  const client = useApolloClient();
  const { getWalkInReservationOrderEvents } = useReservations();

  const { integrationPartners, getIntegrationPartnerSettings } =
    useIntegrationPartners();

  const [session] = useSession();

  useEffect(() => {
    if (session?.currentStore?.id) {
      getIntegrationPartnerSettings({ store: session?.currentStore?.id });
    }
  }, [session?.currentStore?.id, getIntegrationPartnerSettings]);

  const { acceptOrders: acceptOnlineOrders } = useOnlineOrderEvents();

  const handleUnprocessedOrder = useCallback(
    async (currentOrder: Order) => {
      if (!session.deviceProfile?.enableOnlineOrders) return;

      const storeSettings = Object.values(
        integrationPartners as Dictionary<IntegrationPartner>,
      ).find(setting => setting.appName === currentOrder.integrationInfo?.app);

      const isAutoAccept =
        currentOrder.store &&
        storeSettings &&
        storeSettings.preferences?.onlineOrdering?.autoAcceptOrders;
      const isCurrentDevice =
        currentOrder.store &&
        storeSettings &&
        session?.device?.id ===
          storeSettings.preferences?.onlineOrdering?.printDevice;

      // If auto accept is enabled, order is in pending state and current device is assigned to printing
      // ---> accept the order and print kitchen docket
      if (
        currentOrder.status === OrderStatus.CREATED &&
        isAutoAccept &&
        isCurrentDevice
      ) {
        const events = await getWalkInReservationOrderEvents(currentOrder);
        acceptOnlineOrders([{ order: currentOrder, events }]);
      }

      // If order is not in pending state and current device is assigend to printing
      // ---> call print docket and print if any item is not fired
      // ex: Cancel order on partner side
      else if (currentOrder.status !== OrderStatus.CREATED && isCurrentDevice) {
        kitchenOrderEvents.publishToKotUtil({
          orderId: currentOrder?.id,
          preEvents: [],
        });
      }
    },
    [
      acceptOnlineOrders,
      integrationPartners,
      session?.device?.id,
      session.deviceProfile?.enableOnlineOrders,
      getWalkInReservationOrderEvents,
    ],
  );

  const handleOrderSave = useCallback(
    (saveOrder: Order) => {
      if (!!saveOrder?.integrationInfo?.id) {
        handleUnprocessedOrder(saveOrder);
      }
    },
    [handleUnprocessedOrder],
  );

  const [saveOrder] = useMutation(ORDER_SAVE, {
    onCompleted: data => {
      handleOrderSave(data.saveOrder);
    },
  });

  const getOrdersFromCache = useCallback(
    (status: OrderStatus, isOnline?: boolean) => {
      if (client) {
        const clientOrders = client.cache.readQuery({
          query: GET_ORDERS,
          returnPartialData: true,
          variables: { filter: { status, ...(isOnline && { isOnline }) } },
        }) as { orders: Order[] };
        const orders = clientOrders?.orders;
        return orders ? orders : [];
      }
    },
    [client],
  );

  const isValidOrderEvents = (
    events: OrderEvent[],
    isExistingOrder: boolean,
    lastProcessedEvent: string,
  ) => {
    return isExistingOrder
      ? orderUpdateEventsValidation(events, lastProcessedEvent)
      : true;
  };

  /**
   * Filter events to process
   * @param events All events sent to process.
   * @param lastProcessedEvent Last processed event.
   * @returns Events left to process after 'prevEventId'.
   */
  const filterEventsToProcess = (
    events: OrderEvent[],
    lastProcessedEvent: string,
  ) => {
    const nextEventToProcessIndex = events.findIndex(
      x => x.previous === lastProcessedEvent,
    );
    if (nextEventToProcessIndex === -1) return [];
    return events.slice(nextEventToProcessIndex);
  };

  const hasOrderLengthMismatch = (
    newEventsCount: number,
    existingOrder: Order | null | undefined,
  ): boolean => {
    return existingOrder?.eventsCount !== newEventsCount;
  };

  const orderEventsHandler = useCallback(
    async (events: OrderEvent[]) => {
      const openOrders = getOrdersFromCache(OrderStatus.IN_PROGRESS) || [];
      const completedOrders = getOrdersFromCache(OrderStatus.COMPLETED) || [];
      const pendingOnlineOrders =
        getOrdersFromCache(OrderStatus.CREATED, true) || [];
      const openOnlineOrders =
        getOrdersFromCache(OrderStatus.IN_PROGRESS, true) || [];
      const mergedOrder = getOrdersFromCache(OrderStatus.MERGED) || [];
      const orders = [
        ...openOrders,
        ...completedOrders,
        ...pendingOnlineOrders,
        ...openOnlineOrders,
        ...mergedOrder,
      ];

      const beginEvent = events[0];
      const isRefundOrder =
        beginEvent.action === OrderAction.ORDER_REFUND_INITIATE;
      const orderId = isRefundOrder
        ? (beginEvent as InitiateRefundEvent)?.refundOf
        : beginEvent.orderId;
      let existingOrder = orders?.find((order: Order) => order.id === orderId);
      const isExistingOrder = existingOrder ? true : false;
      let eventsNotProcessed =
        isExistingOrder && !isRefundOrder
          ? filterEventsToProcess(events, existingOrder?.prevEventId as string)
          : events;

      const hasMismatchedEvents = hasOrderLengthMismatch(
        events.length,
        existingOrder,
      );

      if (existingOrder && hasMismatchedEvents) {
        existingOrder = undefined;
        eventsNotProcessed = events;
      }

      const isValid: boolean = isRefundOrder
        ? true
        : isValidOrderEvents(
            eventsNotProcessed,
            isExistingOrder,
            existingOrder?.prevEventId || '',
          );
      if (isValid) {
        const computedOrder = existingOrder
          ? computeOrderState(eventsNotProcessed, existingOrder)
          : computeOrderState(eventsNotProcessed);

        // Set lastSyncedEventId
        computedOrder.lastSyncedEventId = computedOrder.prevEventId;
        if (
          !!computedOrder.integrationInfo?.id &&
          isValidOnlineOrder(computedOrder) &&
          session?.deviceProfile?.enableOnlineOrders
        ) {
          const pendingOrders = pendingOnlineOrders?.length ?? 0;
          if (computedOrder.status === OrderStatus.CREATED) {
            pendingOnlineOrdersCountVar(pendingOrders + 1);
            alertAndPrintTriggerVar({
              alert: true,
              app: computedOrder?.integrationInfo?.app,
              print: false,
            });
          } else {
            pendingOnlineOrdersCountVar(
              !!pendingOrders ? pendingOrders - 1 : 0,
            );
          }
        }
        saveOrder({ variables: { data: computedOrder } });
      }
    },
    [getOrdersFromCache, saveOrder, session?.deviceProfile?.enableOnlineOrders],
  );

  return useMemo(
    () => ({
      orderEventsHandler,
      handleOrderSave,
    }),
    [orderEventsHandler, handleOrderSave],
  );
};
