import React, { useCallback, useState } from 'react';
import {
  View,
  Text,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { RESERVATION_STATUS, Reservation } from '@oolio-group/domain';
import styles from './ReservationSidePanel.styles';
import theme from '../../../common/default-theme';
import Icon from '../../Icon/Icon';
import Sticker from '../../Shared/Sticker/Sticker';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { formatReservationTimeAndDate } from '../../../screens/POS/Reservations/utils';
import { capitalCase } from 'change-case';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import { useReservations } from '../../../hooks/app/reservations/useReservations';
import { useNotification } from '../../../hooks/Notification';
import isToday from 'date-fns/isToday';
import { addOptimisticReservation } from '../../../screens/POS/Reservations/optimisticReservationsUtils';

export interface ReservationSidePanelProps {
  loading?: boolean;
  reservation: Reservation;
  showPanel: boolean;
  disableBtn?: boolean;
  onClose: () => void;
  onOrderView?: (orderId: string) => void;
  setReservations: Function;
  selectedDate?: string;
}

interface DetailsTileProps {
  title: string;
  value: string | number;
  testID: string;
}

export const DetailRow: React.FC<DetailsTileProps> = ({
  title,
  value,
  testID,
}) => {
  return (
    <View style={styles.row}>
      <Text style={styles.rowTitle}>{title}</Text>
      <Text testID={testID} style={styles.rowValue}>
        {value}
      </Text>
    </View>
  );
};

const ReservationSidePanel: React.FC<ReservationSidePanelProps> = ({
  loading,
  reservation,
  showPanel,
  onClose,
  setReservations,
  selectedDate,
}: ReservationSidePanelProps) => {
  const safeHeight = theme.useSafeHeight();
  const { translate } = useTranslation();
  const { createOrderForReservation, unseatReservation } = useReservations();
  const [isUpdatingReservation, setIsUpdatingReservation] =
    useState<boolean>(false);
  const { showNotification } = useNotification();
  const { reservationDateTime } = formatReservationTimeAndDate(
    reservation.real_datetime_of_slot,
    reservation.duration,
  );

  const { formatCurrency } = useCurrency();
  const detailsData: DetailsTileProps[] = [
    {
      testID: 'reservation-duration',
      title: translate('reservations.reservationTime'),
      value: reservationDateTime,
    },
    {
      testID: 'reservation-status',
      title: translate('reservations.status'),
      value: capitalCase(reservation.status_display || ''),
    },
    {
      testID: 'reservation-note',
      title: translate('reservations.reservationNote'),
      value: reservation.notes || '-',
    },
    {
      testID: 'reservation-sectionAndTable',
      title: translate('reservations.sectionAndTable'),
      value:
        reservation?.venue_seating_area_name +
        '  ' +
        (reservation.table_numbers?.join(', ') || ''),
    },
    {
      testID: 'reservation-deposit',
      title: translate('reservations.deposit'),
      value: formatCurrency(reservation.deposit || 0) || '-',
    },
    {
      testID: 'reservation-customerGuests',
      title: translate('reservations.customerGuests'),
      value: `${reservation.full_name} (x ${reservation.max_guests})`,
    },
    {
      testID: 'reservation-customerContact',
      title: translate('reservations.customerContact'),
      value: `${reservation.phone_number}\n${reservation.email}`,
    },
    {
      testID: 'reservation-integration',
      title: translate('reservations.integration'),
      value: 'Sevenrooms',
    },
  ];

  const updateReservationStatus = useCallback(
    (referenceCode: string, newStatus: string, newStatusDisplay: string) => {
      if (isToday(new Date(selectedDate || 0))) {
        addOptimisticReservation(referenceCode, {
          status: newStatus,
          status_display: newStatusDisplay,
        });
      } else {
        setReservations((prevReservations: Reservation[]) =>
          prevReservations.map(preRes =>
            preRes.reference_code === referenceCode
              ? {
                  ...preRes,
                  status: newStatus,
                  status_display: newStatusDisplay,
                }
              : preRes,
          ),
        );
      }
    },
    [setReservations, selectedDate],
  );

  const onUnseatReservation = useCallback(async () => {
    setIsUpdatingReservation(true);
    try {
      await unseatReservation(reservation.reference_code);
      onClose();
      updateReservationStatus(
        reservation.reference_code,
        RESERVATION_STATUS.CONFIRMED,
        RESERVATION_STATUS.CONFIRMED,
      );
    } catch (e) {
      const errMsg = (e as Error)?.message || '';
      showNotification({
        error: true,
        message: errMsg,
      });
    } finally {
      setIsUpdatingReservation(false);
    }
  }, [
    updateReservationStatus,
    unseatReservation,
    reservation,
    showNotification,
    onClose,
  ]);

  const onSeatReservation = useCallback(async () => {
    setIsUpdatingReservation(true);
    try {
      const { message, success } = await createOrderForReservation(reservation);
      if (!success) {
        showNotification({
          error: true,
          message: `${translate('reservations.failedSeatedReservation', {
            id: reservation.reference_code,
          })} - ${message ?? ''}`,
        });
      } else {
        onClose();
        updateReservationStatus(
          reservation.reference_code,
          RESERVATION_STATUS.ARRIVED,
          RESERVATION_STATUS.SEATED,
        );
      }
    } finally {
      setIsUpdatingReservation(false);
    }
  }, [
    updateReservationStatus,
    createOrderForReservation,
    reservation,
    showNotification,
    onClose,
    translate,
  ]);

  const showSeatAction =
    [
      RESERVATION_STATUS.BOOKED,
      RESERVATION_STATUS.NOT_RECONCILED,
      RESERVATION_STATUS.CONFIRMED,
      RESERVATION_STATUS.PRE_PARTIALLY_ARRIVED,
      RESERVATION_STATUS.PRE_ARRIVED,
      RESERVATION_STATUS.PARTIALLY_ARRIVED,
      RESERVATION_STATUS.ARRIVED,
    ].includes(reservation.status as RESERVATION_STATUS) &&
    reservation.status_display?.toLowerCase() !==
      RESERVATION_STATUS.SEATED.toLowerCase();

  if (!showPanel) {
    return <></>;
  }

  return (
    <View style={[styles.container, { height: safeHeight }]}>
      {loading || isUpdatingReservation ? (
        <View style={styles.placeholder}>
          <ActivityIndicator size={20} color={theme.colors.grey5} />
        </View>
      ) : reservation ? (
        <>
          <View style={styles.title}>
            <TouchableOpacity
              testID="btn-close"
              style={styles.btnClose}
              onPress={onClose}
            >
              <Icon name="times" size={20} color={theme.colors.dark} />
            </TouchableOpacity>
            <Text testID="reservation-no" style={styles.titleText}>
              {translate('reservations.reservationCodeTitle', {
                id: reservation?.reference_code,
              })}
            </Text>
            <Sticker
              testID="sticker-tableNo"
              type={'primaryLight'}
              label={
                reservation?.table_numbers
                  ? reservation.table_numbers.join(', ')
                  : ''
              }
              containerStyle={styles.tableNo}
            />
          </View>
          <ScrollView style={styles.content}>
            <View testID="reservation-details">
              {detailsData.map((data, index) => (
                <DetailRow
                  testID={data.testID}
                  title={data.title}
                  value={data.value}
                  key={`${index} ${data.value}`}
                />
              ))}
            </View>
            <TreatButton
              testID="btn-seatReservation"
              height={60}
              type={'positive'}
              onPress={showSeatAction ? onSeatReservation : onUnseatReservation}
              label={
                showSeatAction
                  ? translate('reservations.seatReservation')
                  : translate('reservations.unseatReservation')
              }
              isLoading={loading}
            />
          </ScrollView>
        </>
      ) : null}
    </View>
  );
};

export default ReservationSidePanel;
